import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "message" */ '../views/Message.vue')
  }, {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "message" */ '../views/live.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue')
  },
  {
    path: '/publicWelfare',
    name: 'publicWelfare',
    component: () => import(/* webpackChunkName: "publicWelfare" */ '../views/PublicWelfare.vue')
  },
  {
    path: '/story',
    name: 'story',
    component: () => import(/* webpackChunkName: "story" */ '../views/Story.vue')
  },
  {
    path: '/welfareActivity',
    name: 'welfareActivity',
    component: () => import(/* webpackChunkName: "welfareActivity" */ '../views/WelfareActivity.vue')
  },
  {
    path: '/runAcitvety',
    name: 'runAcitvety',
    component: () => import(/* webpackChunkName: "runAcitvety" */ '../views/RunAcitvety.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "mine" */ '../views/Mine.vue')
  },
  {
    path: '/circleDetail',
    name: 'circleDetail',
    component: () => import(/* webpackChunkName: "circleDetail" */ '../views/CircleDetail.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "mine" */ '../views/info.vue')
  },
  {
    path: '/videoList',
    name: 'videoList',
    component: () => import(/* webpackChunkName: "mine" */ '../views/videoList.vue')
  },
  {
    path: '/videoDes',
    name: 'videoDes',
    component: () => import(/* webpackChunkName: "mine" */ '../views/videoDes.vue')
  },
  {
    path: '/myActives',
    name: 'myActives',
    component: () => import(/* webpackChunkName: "mine" */ '../views/MyActives.vue')
  },
  {
    path: '/postsList',
    name: 'postsList',
    component: () => import(/* webpackChunkName: "mine" */ '../views/postsList.vue')
  },
  {
    path: '/sjapp',
    name: 'downLoad',
    component: () => import(/* webpackChunkName: "mine" */ '../views/downLoad.vue')
  },
  {
    path: '/page/circle/CircleDetail',
    name: 'sharePostDetail',
    component: () => import(/* webpackChunkName: "mine" */ '../views/sharePostDetail.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeResolve(to => {
  window.scrollTo(0, 0);
});
export default router;
