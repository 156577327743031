import emotions from "../../../public/emotion/emotions.json";

//表情转换 [微笑] => <img src="xxx">
export const formatEmotions = (content: string) => {
  const emotionUrl = '//livestatic.polyv.net/assets/images/em/';
  if (!content) return content;
  // eslint-disable-next-line no-useless-escape
  return content.replace(/(\[([^\[^\]]*)\])/g, ($1) => {
    const cur = emotions.find((item: { title: any; }) => {
      if ($1 === `[${item.title}]`) return item;
    });
    if (cur) {
      return `<img class="emotionimg" src="${emotionUrl + cur.fileName}"/>`;
    } else {
      return content;
    }
  });
}

// 匹配所有“\r\n”的正则表达式，把换行符替换成 br
export const formatWrap = (string: string) => {
  if (!string) return;
  return string.replace(/(\r\n)|(\n)/g, '<br>');
}